/**
 * Implement Gatsby's Browser APIs in this file.
 * https://pagepro.co/blog/the-basics-you-should-know-about-gatsby-api-files/
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  console.log("ReactDOM.render has executed")
}
